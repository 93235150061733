import React from "react";
import styled from "styled-components";
import Button from "@kiwicom/orbit-components/lib/Button";
// import Edit from "@kiwicom/orbit-components/lib/icons/Edit";
// import GalleryDemo from "./../assets/gallery-demo.png";
import useWindowSize from "./../hooks/useWindowSize";
import DemoCardWithEnv from "./../assets/main-demo-card.png";
import DemoCard from "./../assets/demo-card.png";
import Env from "./env";
import Card from "./card";
import { sendAmplitudeData } from "../service/amplitude";

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 16px;
  padding-top: 60px;
  margin-bottom: 0px;
  width: 100%;
  max-width: 1200px;
  height: 600px;
  /* background-color: rgba(0, 0, 0, 0.1); */

  h1 {
    position: relative;
    font-weight: 400;
    font-size: 60px;
    line-height: 120%;
    text-align: left;
    margin: 0 0 32px 0;
    max-width: 100%;
    color: #fff; //#eb7878
    font-family: "Sofia", cursive;

    @media only screen and (max-width: 768px) {
      width: 100%;
      text-align: center;
      font-size: 30px;
      margin: 0 0 24px 0;
    }
  }

  h3 {
    position: relative;
    /* font-weight: 400; */
    font-size: 22px;
    line-height: 30px;
    text-align: left;
    /* margin: 0.5em 0 1.5em; */
    margin: 0 0 48px 0;
    max-width: 500px;
    color: #fff; //#3f3f3f;

    font-family: "Poppins";
    font-weight: 300;

    @media only screen and (max-width: 768px) {
      font-size: calc(16px);
      line-height: 1.5;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  button {
    background: #eb7878; //#516778 !important;
    border-radius: 12px;

    * {
      white-space: nowrap;
    }
    &:hover {
      background: #e26c6c; //#516778 !important;
    }
    /* border-radius: 27px !important; */
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    min-height: calc(min(100vh, 100vw));
    margin-bottom: 60px;
    padding: 0px 12px;
    padding-top: 0px;
    /* padding-top: 100vh; */
  }
`;

const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1.5;
  /* background: rgba(100, 200, 255, 0.1); */
  padding-left: 2.5vw;
  @media only screen and (max-width: 768px) {
    align-items: center;
    justify-content: flex-start;
    padding-left: 0vw;
    /* margin-top: 60px; */
  }
`;
const RightPanel = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  transform: rotate(5deg);

  @media only screen and (max-width: 768px) {
    margin-top: 200px;
    transform: rotate(0deg);
  }
  /* background: rgba(0, 0, 0, 0.1); */

  /* img {
    width: 400px;
    height: auto;
    align-self: flex-start;
    margin-top: 10%;
    @media only screen and (max-width: 768px) {
      width: 90%;
    }
  } */
`;

export const _ = () => {
  const windowSize = useWindowSize();
  const [size, setSize] = React.useState({
    baseSize: 200,
    cardSize: 200 * 0.8,
  });
  const handleScrollDown = () => {
    sendAmplitudeData("[Gallery] Click CTA button");
    window.scrollTo({
      top: 750,
      left: 0,
      behavior: "smooth",
    });
  };
  // const baseSize = 320;
  // const cardSize = 200;
  React.useEffect(() => {
    if (windowSize?.height && windowSize?.width) {
      if (windowSize?.width > 768) {
        setSize({
          baseSize: 360,
          cardSize: 220
        });
      } else {
        setSize({
          baseSize: 250,
          cardSize: 150
        });
      }
    }
  }, [windowSize]);
  const { baseSize, cardSize } = size;
  return (
    <HeaderContainer>
      <LeftPanel>
        <h1>Greetings made simple.</h1>
        <h3>
          Celebrate Christmas and other special occasions by sending animated cards to the people you love.
        </h3>
        <Button size="large" onClick={handleScrollDown}>
          View Cards
        </Button>
      </LeftPanel>
      <RightPanel>
        {/* <img src={DemoCardWithEnv} alt={"demo-salut-card"} /> */}
        <Env baseSize={baseSize} bottom={windowSize?.width > 768 ? 20 : ( -baseSize / 2)} left={"50%"}>
          <Card
            demo={true}
            noAnimation={false}
            isInvert={false}
            top={-baseSize * (windowSize?.width > 768 ? 0.95 : 0.95)}
            cardSize={cardSize}
            baseSize={baseSize}
            isEditing={false}
            frontPageOnClick={() => {
              sendAmplitudeData("[General] Click Demo Card");
            }}
            previewCover={DemoCard}
          />
        </Env>
      </RightPanel>
      {/*  */}
      {/*  */}
    </HeaderContainer>
  );
};
export default _;
