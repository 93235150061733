import React from "react";
import styled, { keyframes } from "styled-components";
import { CirclePicker } from "react-color";
import { envFilterState } from "../store/atom";
import { useRecoilState } from "recoil";
import { sendAmplitudeData } from "../service/amplitude";

const StyledCirclePicker = styled(CirclePicker)`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
const mappingFilter = (color: any) => {
  switch (color.hex) {
    case "#f54551":
      return {
        id: 1,
        hue: 0,
      };
    case "#7e4fce":
      return {
        hue: 300,
        id: 2,
      };
    case "#2196f3":
      return {
        hue: 210,
        id: 3,
      };
    case "#129f91":
      return {
        hue: 170,
        id: 4,
      };
    case "#c48a33":
      return {
        hue: 40,
        id: 5,
      };
    default:
      return {
        hue: 0,
        id: 1,
      };
  }
};
export const ColorPicker = () => {
  const [envFilterValue, setEnvFilterValue] = useRecoilState(envFilterState);
  const [color, setColor] = React.useState("#fff");
  const handleChangeComplete = (color: any, event: any) => {
    sendAmplitudeData("[Editor] Change envelope color", {
      color: color,
      id: mappingFilter(color)?.["id"],
    });
    setEnvFilterValue(mappingFilter(color)?.["hue"]);
    setColor(color.hex);
  };

  return (
    <StyledCirclePicker
      color={color}
      width={"100%"}
      circleSize={36}
      colors={[
        "#f54551", // filter : hue-rotate(0deg);
        "#7e4fce", // filter : hue-rotate(300deg);
        "#2196f3", // filter : hue-rotate(210deg);
        "#129f91", // filter : hue-rotate(170deg);
        "#c48a33", // filter : hue-rotate(40deg);
      ]}
      onChangeComplete={handleChangeComplete}
    />
  );
};

export default ColorPicker;
