import React, { useState, useRef } from "react";
import Slider from "react-slick";
import styled, { keyframes, createGlobalStyle, css } from "styled-components";
import { useRecoilState } from "recoil";
import { cardMainGraphState } from "./../store/atom";
import { sendAmplitudeData } from "../service/amplitude";
// import { xmasImages } from "./../service/utils";

const GlobalStyle = createGlobalStyle`
  .slick-slide {
    transform: scale(0.7);
    opacity: 0.9;
    transition: all 0.3s ease;

    &.slick-center {
      opacity: 1;
      transform: scale(1)
    }
  }
`;
const StyledSlider = styled.div`
  width: 80%;
  max-width: 640px;
`;
const Container = styled.div`
  /* background: #ddd; */
  width: auto;

  height: 80px;
  cursor: pointer;

  display: flex !important;
  justify-content: center;
  align-items: center;

  > img {
    width: auto;
    height: 80px;
  }
`;
const ArrowComponent = styled.div`
  width: 30px;
  height: 30px;
  background: transparent;

  &::before {
    width: 30px;
    height: 30px;
    font-size: 30px;
    line-height: 1;
    opacity: 0.75;
    color: #0f1221;
  }
`;

function SampleArrow(props) {
  const { className, style, type = null, ...otherProps } = props;
  return (
    <ArrowComponent
      className={className}
      style={{ ...style, display: "block" }}
      onClick={() => {
        sendAmplitudeData("[Editor] change graphic", { from: `${type} arrow` });
      }}
      {...otherProps}
    />
  );
}

export const SimpleSlider = (props) => {
  const { handleUpdate } = props;
  const [currentId, setCurrentId] = useRecoilState(cardMainGraphState);
  const [localImgSrc, setLocalImgSrc] = useState([]);
  const { imgSrc } = props;
  const sliderRef = useRef();
  const settings = {
    // dots: true,
    initialSlide: 0,
    infinite: true,
    centerMode: true,
    centerPadding: "10px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
    onSwipe: () => {
      sendAmplitudeData("[Editor] change graphic", { from: "swipe" });
    },
    nextArrow: <SampleArrow type={"next"} />,
    prevArrow: <SampleArrow type={"prev"} />,
    beforeChange: (oldId, newId) => {
      setCurrentId(newId);
      handleUpdate(newId);
    },
  };
  React.useEffect(() => {
    if (sliderRef?.current && currentId) {
      setTimeout(() => {
        sliderRef?.current?.slickGoTo(currentId);
      }, 300);
    }
    if (localImgSrc !== imgSrc) {
      setLocalImgSrc(imgSrc);
      handleUpdate(currentId);
    }
  }, [imgSrc]);
  return (
    <StyledSlider>
      <GlobalStyle />
      <Slider {...settings} ref={sliderRef}>
        {imgSrc.map((elm, id) => (
          <Container
            key={id}
            onClick={() => {
              sendAmplitudeData("[Editor] change graphic", { from: "tap" });
            }}
          >
            <img src={elm} alt={"card cover design"} />
          </Container>
        ))}
      </Slider>
    </StyledSlider>
  );
};

export default SimpleSlider;
