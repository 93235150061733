import React from "react";
import styled from "styled-components";
// COMPONENTS
import Header from "../components/header";
import HeaderSection from '../components/headerSection';
import CardsSection from '../components/cardsSection';
import BGSection from '../components/BgSection';
import { sendAmplitudeData } from '../service/amplitude';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  > div.galley {
    width: 100%;
    height: 100%;
    padding-top: 64px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Container = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 100%;
  background: white;
  padding: 48px;
  padding-top: 15vh;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 768px) {
    padding: 80px 16px 16px;
    overflow: hidden;
  }
`;

export const Gallery = () => {
  React.useEffect(() => {
    sendAmplitudeData('[Gallery] Enter')
  }, [])
  return (
    <Wrapper>
      <Header />
      <Container>
        <BGSection />
        <HeaderSection />
        <CardsSection />
      </Container>
    </Wrapper>
  );
};

export default Gallery;
