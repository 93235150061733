import React from "react";
import styled from "styled-components";
import star from "./../assets/star.png";

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background: #32B990;
  background-image: url(${star});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const _ = () => {
  return (
    <Background />
  );
};
export default _;
