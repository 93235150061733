import React, { useState, useMemo, useCallback } from "react";
import Lottie from "react-lottie";
import animationData from "../assets/lottie-loading.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

function UncontrolledLottie() {
  return (
    <div>
      <Lottie options={defaultOptions} height={200} width={200} />
    </div>
  );
}

export default UncontrolledLottie;
