import { atom } from "recoil";

export const stepsState = atom({
  key: "stepsState",
  default: 0, // 0: style / 1: content / 2: setting
});

export const cardTextState = atom({
  key: "cardTextState",
  default: "",
});
export const cardBgState = atom({
  key: "cardBgState",
  default: "",
});

export const cardIdState = atom({
  key: "cardIdState",
  default: -1,
});

export const cardMainGraphState = atom({
  key: "cardMainGraphState",
  default: 0,
});
export const cardMainGraphSrcState = atom({
  key: "cardMainGraphSrcState",
  default: "",
});

export const canvasToImageBase64State = atom({
  key: "canvasToImageBase64State",
  default: "",
});

export const envFilterState = atom({
  key: "envFilterState",
  default: 0,
});

export const fontFamilyState = atom({
  key: "fontFamilyState",
  default: "Cedarville Cursive",
});

export const sliderImgSrcState = atom({
  key: "sliderImgSrcState",
  default: [],
});
