import React from "react";
import Linkify from "react-linkify";

export const LinkedElm = (props) => {
  return (
    <Linkify
      properties={{ target: "_blank" }}
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a target="blank" href={decoratedHref} key={key}>
          {decoratedText}
        </a>
      )}
    >
      <p>{props.children}</p>
    </Linkify>
  );
};

export default LinkedElm;
