export const xmasImages = [...new Array(7)].map((elm, id) => ({
  id: id + 1,
  src: require(`./../assets/list/xmas/${id + 1}.png`),
  title: "",
  description: "",
}));

export const baseImage = [...new Array(5)].map((elm, id) => ({
  id: id + 1,
  src: require(`./../assets/cards/base/${id + 1}.png`),
  title: "",
  description: "",
}));

// encode(decode) html text into html entity
export const decodeHtmlEntity = (str) => {
  return str.replace(/&#(\d+);/g, function (match, dec) {
    return String.fromCharCode(dec);
  });
};

export const encodeHtmlEntity = (str) => {
  let buf = [];
  for (var i = str.length - 1; i >= 0; i--) {
    buf.unshift(["&#", str[i].charCodeAt(), ";"].join(""));
  }
  return buf.join("");
};

export const srcJson = [
  {
    id: 1,
    name: "xmas1",
    tag: "xmas",
    mainSrc: ["1", "2", "3", "4", "5", "6", "7"],
    mainOffset: [50, 50], // as percentage
    gallerySrc: ["1", "2"],
    altText: "Plain Christmas card with tree, letters, presents",
  },
  {
    id: 2,
    name: "newyear",
    tag: "new year",
    mainSrc: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
    mainOffset: [50, 60], // as percentage
    gallerySrc: ["1", "2"],
    altText: "New year ecard",
  },
  {
    id: 3,
    name: "xmas3",
    tag: "xmas",
    mainSrc: ["1", "2", "3", "4", "5", "6", "7"],
    mainOffset: [50, 55], // as percentage
    gallerySrc: ["1", "2"],
    altText: "Classic 3D Holiday card",
  },
  {
    id: 4,
    name: "xmas4",
    tag: "xmas",
    mainSrc: ["1", "2", "3", "4", "5", "6"],
    mainOffset: [50, 40], // as percentage
    gallerySrc: ["1", "2"],
    altText: "Cute doodle Christmas card",
  },
  {
    id: 5,
    name: "xmas5",
    tag: "xmas",
    mainSrc: ["1", "2", "3", "4", "5", "6", "7", "8"],
    mainOffset: [50, 47.5], // as percentage
    gallerySrc: ["4", "3"],
    altText: "Festive Christmas holiday card with toys, ornaments, presents, tree",
  },
  {
    id: 6,
    name: "xmas6",
    tag: "xmas",
    mainSrc: ["1", "2", "3", "4", "5", "6", "7", "8"],
    mainOffset: [50, 78], // as percentage
    gallerySrc: ["3", "2"],
    altText: "Christmas tree card",
  },
  {
    id: 7,
    name: "xmas7",
    tag: "xmas",
    mainSrc: ["1", "2", "3", "4", "5", "6", "7"],
    mainOffset: [59, 27], // as percentage
    gallerySrc: ["3", "2"],
    altText: "Cozy Christmas at home card with wreath & cats",
  },
  {
    id: 8,
    name: "xmas8",
    tag: "xmas",
    mainSrc: ["1", "2", "3", "4", "5", "6", "7"],
    mainOffset: [50, 40], // as percentage
    gallerySrc: ["1", "5"],
    altText: "Classic greetings card with gifts & presents",
  },
  {
    id: 9,
    name: "newyear1",
    tag: "new year",
    mainSrc: ["1", "2", "3", "4", "5", "6", "7", "8"],
    mainOffset: [47.5, 47.5], // as percentage
    gallerySrc: ["3", "2"],
    altText: "PicCollage new year card",
  },
  {
    id: 10,
    name: "newyear2",
    tag: "new year",
    mainSrc: ["1", "2", "3", "4", "5", "6"],
    mainOffset: [65, 75], // as percentage
    gallerySrc: ["3", "2"],
    altText: "New year ecard by PicCollage",
  },
  {
    id: 11,
    name: "birthday",
    tag: "birthday",
    mainSrc: ["1", "2", "3", "4", "5", "6", "7", "8"],
    mainOffset: [50, 60], // as percentage
    gallerySrc: ["3", "2"],
    altText: "Happy birthday card for kids and babies",
  },
  {
    id: 12,
    name: "xmas9",
    tag: "xmas",
    mainSrc: ["1", "2", "3", "4", "5", "6", "7"],
    mainOffset: [50, 72.5], // as percentage
    gallerySrc: ["3", "2"],
    altText: "Blue Christmas card",
  },
  {
    id: 13,
    name: "xmas10",
    tag: "xmas",
    mainSrc: ["1", "2", "3", "4", "5", "6", "7", "8"],
    mainOffset: [50, 40], // as percentage
    gallerySrc: ["3", "2"],
    altText: "Cute Christmas card with poinsettia",
  },
  {
    id: 14,
    name: "xmas11",
    tag: "xmas",
    mainSrc: ["1", "2", "3", "4", "5", "6", "7"],
    mainOffset: [50, 47.5], // as percentage
    gallerySrc: ["3", "4"],
    altText: "Flying Santa sleigh holiday card",
  },
  {
    id: 15,
    name: "xmas12",
    tag: "xmas",
    mainSrc: ["1", "2", "3", "4", "5", "6", "7"],
    mainOffset: [50, 40], // as percentage
    gallerySrc: ["2", "7"],
    altText: "Christmas card with ornaments",
  },
  {
    id: 16,
    name: "xmas13",
    tag: "xmas",
    mainSrc: ["1", "2", "3", "4", "5", "6", "7"],
    mainOffset: [50, 70], // as percentage
    gallerySrc: ["3", "4"],
    altText: "Santa Claus card",
  },
  {
    id: 17,
    name: "xmas15",
    tag: "xmas",
    mainSrc: ["1", "2", "3", "4", "5", "6", "7", "8"],
    mainOffset: [50, 55], // as percentage
    gallerySrc: ["6", "4"],
    altText: "Merry Christmas with Presents Card",
  },
  {
    id: 18,
    name: "birthday3",
    tag: "birthday",
    mainSrc: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
    mainOffset: [50, 35], // as percentage
    gallerySrc: ["3", "4"],
    altText: "Pink birthday cake card",
  },
  {
    id: 19,
    name: "birthday4",
    tag: "birthday",
    mainSrc: ["1", "2", "3", "4", "5", "6"],
    mainOffset: [50, 50], // as percentage
    gallerySrc: ["3", "4"],
    altText: "Colorful birthday card with oil paints",
  },
  {
    id: 20,
    name: "vd_1",
    tag: "valentine",
    mainSrc: ["1", "2", "3", "4", "5"],
    mainOffset: [50, 50], // as percentage
    gallerySrc: ["3", "4"],
    altText: "Simple purple valday card",
  },
  {
    id: 21,
    name: "vd_2",
    tag: "valentine",
    mainSrc: ["1", "2", "3", "4", "5", "6", "7", "8"],
    mainOffset: [50, 35], // as percentage
    gallerySrc: ["3", "4"],
    altText: "Lovely black Valentine’s Day card",
  },
  {
    id: 22,
    name: "vd_3",
    tag: "valentine",
    mainSrc: ["1", "2", "3", "4", "5", "6"],
    mainOffset: [50, 45], // as percentage
    gallerySrc: ["3", "4"],
    altText: "Watercolor & doodle love letter card",
  },
  {
    id: 23,
    name: "vd_4",
    tag: "valentine",
    mainSrc: ["1", "2", "3", "4", "5", "6", "7"],
    mainOffset: [50, 35], // as percentage
    gallerySrc: ["3", "4"],
    altText: "Bright Valentine’s Day card with hearts",
  },
  {
    id: 24,
    name: "vd_5",
    tag: "valentine",
    mainSrc: ["1", "2", "3", "4", "5", "6", "7"],
    mainOffset: [50, 35], // as percentage
    gallerySrc: ["3", "4"],
    altText: "Love letter with flowers card",
  },
  {
    id: 25,
    name: "vd_6",
    tag: "valentine",
    mainSrc: ["1", "2", "3", "4", "5", "6"],
    mainOffset: [75, 50], // as percentage
    gallerySrc: ["3", "4"],
    altText: "Love Letter card with stickers",
  },
  {
    id: 26,
    name: "vd_7",
    tag: "valentine",
    mainSrc: ["1", "2", "3", "4", "5", "6"],
    mainOffset: [51, 46], // as percentage
    gallerySrc: ["3", "4"],
    altText: "Happy valentine’s day with hearts card",
  },
  {
    id: 27,
    name: "vd_8",
    tag: "valentine",
    mainSrc: ["1", "2", "3", "4", "5", "6"],
    mainOffset: [50, 50], // as percentage
    gallerySrc: ["3", "4"],
    altText: "Glowing neon light love letter card",
  },
  // Mother's day
  {
    id: 28,
    name: "mother_1",
    tag: "mother",
    mainSrc: ["1", "2", "3", "4", "5", "6", "7"],
    mainOffset: [50, 50], // as percentage
    gallerySrc: ["3", "4"],
    altText: "Best mom in the world card",
  },
  {
    id: 29,
    name: "mother_2",
    tag: "mother",
    mainSrc: ["1", "2", "3", "4", "5", "6"],
    mainOffset: [47.5, 33], // as percentage
    gallerySrc: ["3", "4"],
    altText: "Mother’s Day card from child",
  },
  {
    id: 30,
    name: "mother_3",
    tag: "mother",
    mainSrc: ["1", "2", "3", "4", "5", "6"],
    mainOffset: [50, 50], // as percentage
    gallerySrc: ["3", "4"],
    altText: "Flower Mother’s Day card",
  },
  {
    id: 31,
    name: "birthday2",
    tag: "birthday",
    mainSrc: ["1", "2", "3", "4", "5", "6", "7"],
    mainOffset: [50, 40], // as percentage
    gallerySrc: ["3", "4"],
    altText: "Glitter birthday card ",
  },
  {
    id: 32,
    name: "mother_5",
    tag: "mother",
    mainSrc: ["1", "2", "3", "4", "5", "6"],
    mainOffset: [50, 45], // as percentage
    gallerySrc: ["3", "4"],
    altText: "The best mom card",
  },
  // ---- 2022 new year
  {
    id: 33,
    name: "newyear_3",
    tag: "new year",
    mainSrc: ["1", "2", "3", "4", "5", "6"],
    mainOffset: [65, 80], // as percentage
    gallerySrc: ["3", "4"],
    altText: "New Year party & confetti card",
  },
  {
    id: 34,
    name: "newyear_4",
    tag: "new year",
    mainSrc: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
    mainOffset: [50, 50], // as percentage
    gallerySrc: ["3", "5"],
    altText: "Happy New Year with fireworks & confetti",
  },
  {
    id: 35,
    name: "newyear_5",
    tag: "new year",
    mainSrc: ["1", "2", "3", "4", "5", "6", "7", "8"],
    mainOffset: [50, 50], // as percentage
    gallerySrc: ["3", "4"],
    altText: "Calligraphy welcome new year card",
  },
];
