import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import NavigationBar from "@kiwicom/orbit-components/lib/NavigationBar";
import useWindowSize from "./../hooks/useWindowSize";
import ButtonLink from "@kiwicom/orbit-components/lib/ButtonLink";
import ChevronLeft from "@kiwicom/orbit-components/lib/icons/ChevronLeft";
import Stack from "@kiwicom/orbit-components/lib/Stack";
import { sendAmplitudeData } from "../service/amplitude";

import { useRecoilState } from "recoil";
import { stepsState } from "./../store/atom";
import Logo from "./../assets/logo.png";

export const Header = () => {
  let history = useHistory();
  const params = useParams();
  const windowSize = useWindowSize();
  const location = useLocation();
  const [steps, setSteps] = useRecoilState(stepsState);
  const handlePageTransition = (key: string, step: number) => {
    setSteps(step);

    history.push({
      pathname: key,
      state: {
        from: isShare() ? "share" : null,
      },
    });
  };
  const isEditing = () => {
    return location.pathname.includes("editor");
  };

  const isShare = () => {
    return location.pathname.includes("share");
  };

  return (
    <NavigationBar>
      <Stack direction="row" align="center" justify="between">
        {isEditing() ? null : (
          <ButtonLink
            onClick={() => {
              sendAmplitudeData("[General] Navigate", {
                from: location.pathname,
                to: "gallery",
                trigger: "logo",
              });
              handlePageTransition("/", 0);
            }}
          >
            <div
              style={{
                // maxWidth: "40px",
                overflow: "hidden",
              }}
            >
              <img src={Logo} alt="Salut: Personalized Cards" height="32px" />
            </div>
          </ButtonLink>
        )}

        {isEditing() && (
          <ButtonLink
            iconLeft={<ChevronLeft />}
            type="secondary"
            onClick={() => {
              sendAmplitudeData("[General] Navigate", {
                from: "editor",
                to: "gallery",
                trigger: "back to gallery button",
              });
              handlePageTransition("/", 0);
              setTimeout(() => {
                window.scrollTo({
                  top: 750,
                  left: 0,
                  behavior: "smooth",
                });
              }, 300)
            }}
          >
            Back to Gallery
          </ButtonLink>
        )}
        <Stack justify="end" inline align="center">
          {(isEditing() || isShare()) && (
            <ButtonLink
              type="secondary"
              onClick={() => {
                sendAmplitudeData("[General] Navigate", {
                  from: "editor",
                  to: "preivew",
                  trigger: "preview button",
                });
                handlePageTransition(`/preview/${(params as any)?.id}`, steps);
              }}
            >
              Preview
            </ButtonLink>
          )}
          {/* <ButtonLink
            type="secondary"
            iconLeft={<AccountCircle />}
            title="My account"
          /> */}
        </Stack>
      </Stack>
    </NavigationBar>
  );
};

export default Header;
