import { useState, useEffect } from "react";
export interface IWindowProps {
  width: number;
  height: number;
}
// Hook
export default () => {
  const [windowSize, setWindowSize] = useState<IWindowProps>({
    width: 500,
    height: 500,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width:
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth ||
          1024,
        height:
          window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight ||
          1024,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};
