import React, { FunctionComponent, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import useWindowSize from "./../hooks/useWindowSize";
import { cardIdState } from "./../store/atom";
import * as Firebase from "./../service/firebase";
import { sendAmplitudeData } from "../service/amplitude";
import Card from "./cardv2";
import Observer from "@researchgate/react-intersection-observer";

interface ComponentProps {
  cardSrc?: any;
}
const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
`;

export const _: FunctionComponent<ComponentProps> = (props) => {
  const { cardSrc = undefined } = props;
  const [coverImgSrc, setCoverImgSrc] = useState(undefined);
  const [previewScrapsImgSrc, setPreviewScrapsImgSrc] = useState<any[]>([]);
  const [cardId, setCardId] = useRecoilState(cardIdState);
  const [isInViewPort, setIsInViewPort] = useState(true);
  let history = useHistory();
  const windowSize = useWindowSize();
  const handleNavigate = async () => {
    sendAmplitudeData("[Gallery] Click card", { id: cardSrc.id });
    setCardId(cardSrc.id);
    // set firebase record
    let newCollageKey = Firebase.db.ref().child(`${process.env.REACT_APP_DB_ENDPOINT}`).push().key;
    await Firebase.db.ref(`${process.env.REACT_APP_DB_ENDPOINT}/${newCollageKey}`).set(
      {
        createDate: new Date().getTime() / 1,
      },
      async (err) => {
        if (err) {
          console.log("something went wrong when adding data", err);
        } else {
          sendAmplitudeData("[General] Create record", { id: newCollageKey });
          history.push({
            pathname: `/editor/${newCollageKey}`,
            state: {
              cardId: cardSrc.id,
            },
          });
        }
      }
    );
  };
  useEffect(() => {
    const fetchFrontPage = async () => {
      let res = await Firebase.getImageSrc(
        `simpleGreetingSrc/${cardSrc?.id}/preview.png`
      );
      if (res) {
        setCoverImgSrc(res);
      }
      Promise.all(
        cardSrc?.gallerySrc.map((elm: any) =>
          Firebase.getImageSrc(`simpleGreetingSrc/${cardSrc.id}/${elm}.png`)
        )
      ).then((values) => {
        setPreviewScrapsImgSrc(values);
      });
    };
    fetchFrontPage();
  }, []);
  const handleChange = ({ isIntersecting, intersectionRatio }: any) => {
    setIsInViewPort(isIntersecting && intersectionRatio >= 0.99)
  };
  return (
    <Observer onChange={handleChange} threshold={0.99}>
      <Wrapper onClick={handleNavigate}>
        {coverImgSrc ? (
          <Card
            openable={false}
            hoverable={true}
            cardPageFrontOutsideComponent={
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundImage: `url(${coverImgSrc})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
                role="img"
                aria-label={`${cardSrc.altText}`}
              ></div>
            }
            isHovering={windowSize?.width > 768 ? false : isInViewPort}
            cardPageBackComponent={<div>back</div>}
            previewScrapsImgSrc={previewScrapsImgSrc}
            // isOpen={false}
            size={windowSize?.width > 768 ? 200 : 250}
          />
        ) : null}
      </Wrapper>
    </Observer>
  );
};
export default _;
