import React, { useState } from "react";
// Konva
import { Stage, Layer, Image } from "react-konva";
import styled from "styled-components";
import useImage from "use-image";
import { useRecoilState } from "recoil";
import {
  cardMainGraphState,
  canvasToImageBase64State,
  cardMainGraphSrcState,
  cardIdState,
} from "./../store/atom";
import { baseImage, xmasImages } from "./../service/utils";
// import useCardsData from "../hooks/useCardsData";
import { srcJson } from "./../service/utils";
const StyledStage = styled(Stage)``;

export const Canvas = (props) => {
  const { baseSrc = null, mainGraphSrc = null, onTap = () => {} } = props;
  const stageRef = React.useRef();
  const [canvasSize, setCanvasSize] = useState({
    width: 300,
    height: 460,
  });
  const [mainGraphicOffset, setMainGraphicOffset] = useState({
    x: 300 / 2,
    y: 460 / 2,
  });
  const [currentId, setCurrentId] = useRecoilState(cardMainGraphState);
  const [cardId, setCardId] = useRecoilState(cardIdState);
  const [cardMainGraphSrc, setCardMainGraphSrcState] = useRecoilState(
    cardMainGraphSrcState
  );
  const [canvasToImageBase64, setCanvasToImageBase64] = useRecoilState(
    canvasToImageBase64State
  );
  const [mainCardBgSrc] = useImage(
    baseSrc || baseImage[0]?.src?.default,
    "anonymous"
  );
  const [assetsSrc] = useImage(
    cardMainGraphSrc || xmasImages[currentId || 0]?.src?.default,
    "anonymous"
  );
  // const srcJson = useCardsData();
  React.useEffect(() => {
    function generateBase64() {
      if (stageRef.current) {
        const dataURL = stageRef.current.toDataURL({
          mimeType: "image/png",
          quality: 1,
          pixelRatio: 2,
        });
        setCanvasToImageBase64(dataURL);
      }
    }
    setTimeout(() => {
      generateBase64();
    }, 1000);
  }, [currentId, mainCardBgSrc, assetsSrc, stageRef.current]);

  React.useEffect(() => {
    if (srcJson.length) {
      const _w = props?.cardSize - 8;
      const _h = (props?.cardSize * 6) / 4 - 8;
      setCanvasSize({
        width: _w,
        height: _h,
      });

      setMainGraphicOffset({
        x: _w * (srcJson?.[cardId - 1]?.mainOffset[0] / 100) - _w / 3,
        y: _h * (srcJson?.[cardId - 1]?.mainOffset[1] / 100) - _w / 3,
      });
    }
  }, [props?.cardSize, srcJson]);
  return (
    <StyledStage
      ref={stageRef}
      scaleX={1}
      scaleY={1}
      width={canvasSize?.width}
      height={canvasSize?.height}
      onTap={() => {
        onTap();
      }}
    >
      <Layer listening={false}>
        <Image
          image={mainCardBgSrc}
          width={canvasSize?.width}
          height={canvasSize?.height}
          scaleX={1}
          scaleY={1}
          draggable={false}
        />
      </Layer>
      <Layer listening={true}>
        <Image
          x={mainGraphicOffset.x}
          y={mainGraphicOffset.y}
          image={assetsSrc}
          width={canvasSize?.width / 1.5}
          height={canvasSize?.width / 1.5}
          scaleX={1}
          scaleY={1}
          draggable={false}
        />
      </Layer>
    </StyledStage>
  );
};

export default Canvas;
