import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useCardsData from "../hooks/useCardsData";
// import GalleryCard from './galleryCard';
import { srcJson } from "./../service/utils";
import GalleryCard from "./../components/galleryCard";
import * as Firebase from "./../service/firebase";

interface styledCardWrapperProps {
  id: any;
  key: number;
  column: number;
  row: number;
}

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  margin-top: 5vh;
  max-width: 1200px;
  /* background: rgba(0, 0, 0, 0.1); */
  // display
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 32px;
  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
    margin-top: 15vh;
  }
  @media only screen and (max-width: 560px) {
    grid-template-columns: 1fr;
  }
`;
const CardWrapper = styled.div<styledCardWrapperProps>`
  position: relative;
  width: auto;
  padding-top: 125%; /* 4:3 Aspect Ratio */
  margin-top: ${({ id }) => (id % 2 === 0 ? "64px" : "0px")};

  @media only screen and (max-width: 1200px) {
    margin-top: 32px;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 64px;
  }
`;

export const _ = () => {
  const [cards, setCards] = useState<any[] | null>(null);
  // const srcJson = useCardsData();

  const getCardsOrder = async () => {
    let sortedCardList: any[] = [];
    await Firebase.db
      .ref(process.env.REACT_APP_DB_CARDS_ORDER_ENDPOINT)
      .once("value")
      .then(async (snapshot) => {
        // snapshot
        [
          30,
          29,
          28,
          32,
          31,
          8,
          15,
          16,
          12,
          13,
          17,
          33,
          34,
          14,
          35,
          5,
          6,
          7,
          1,
          3,
          4,
          26,
          20,
          18,
          19,
          24,
          22,
          21,
          23,
          25,
          27,
          11,
        ].forEach((elm) => {
          // sortedCardList.push(srcJson.find((card) => card.id === elm.val()));
          sortedCardList.push(srcJson.find((card) => card.id === elm));
        });
        setCards(sortedCardList);
        // console.log(sortedCardList);
      });
  };

  useEffect(() => {
      getCardsOrder();
  }, []);
  
  return (
    <CardsContainer>
      {cards?.map((card, key: number) => (
        <CardWrapper
          key={key}
          id={key}
          column={key % 4}
          row={Math.floor(key / 4)}
        >
          <GalleryCard cardSrc={card} />
        </CardWrapper>
      ))}
    </CardsContainer>
  );
};
export default _;
