import React, { FunctionComponent } from "react";
import styled, { keyframes, createGlobalStyle, css } from "styled-components";
import { useSpring, animated } from "react-spring";
interface ComponentProps {
  cardPageFrontOutsideComponent: JSX.Element | null;
  cardPageBackComponent: JSX.Element | null;
  onClickFrontCover?: () => void;
  previewScrapsImgSrc?: any;
  hoverable?: Boolean;
  openable?: Boolean;
  isHovering?: Boolean;
  size?: number;
  step?: number;
}
interface styledCardWrapperProps {
  isCardOpen: Boolean;
  isLaydown: Boolean;
  cardSize: number;
}
interface styledCardBaseProps {
  isCardOpen: Boolean;
  cardSize: number;
}

interface scrapProps {
  id?: any;
  imageSrc?: string;
  transform?: string;
}

const CardWrapper = styled.div<styledCardWrapperProps>`
  left: 50%;
  z-index: 1;
  width: ${({ cardSize }) => cardSize && `${cardSize}px`};
  height: ${({ cardSize }) => cardSize && `${(cardSize * 1.5) / 1}px`};

  perspective: 1500px;
  position: absolute;
  transition-property: transform;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.68, -0.25, 0.265, 1.25);
  transform-origin: center center;
  transition-delay: initial;
  transform: ${({ cardSize, isCardOpen, isLaydown }) =>
    isCardOpen
      ? `translate(-50% + ${cardSize / 2}px, 0) rotateZ(0deg)`
      : `translate(-50%, 0) rotateZ(${isLaydown ? 90 : 0}deg)`};

  /* Shadow for the whole card */
  &.isHover {
    &::before {
      left: ${({ isCardOpen }) => (isCardOpen ? "-100%" : "2%")};
      top: ${({ isCardOpen }) => (isCardOpen ? "-100%" : "2%")};
      background: rgba(0, 0, 0, 0.125);
    }
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: ${({ isCardOpen }) => (isCardOpen ? "-100%" : 0)};
    width: ${({ isCardOpen }) => (isCardOpen ? "200%" : "100%")};
    background: transparent;
    top: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    filter: ${({ cardSize }) => cardSize && `blur(${cardSize / 12}px);`};
    transition: all 0.5s cubic-bezier(0.87, -0.11, 0.3, 1);
    ${({ isCardOpen }) =>
      isCardOpen ? "transition-delay: 0.3s" : "transition-delay: 0.1s"};
  }
`;
const CardPage = styled.div<styledCardBaseProps>`
  width: ${({ cardSize }) => (cardSize ? `${cardSize}px` : `400px`)};
  height: ${({ cardSize }) =>
    cardSize ? `${(cardSize * 1.5) / 1}px` : `600px`};
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
  position: absolute;
  outline: 1px solid transparent;
`;
const CardPageFront = styled(CardPage)<styledCardBaseProps>`
  transform-origin: 0 50% 0;
  transform-style: preserve-3d;
  transform: ${({ isCardOpen }) =>
    isCardOpen ? `rotateY(-180deg)` : `rotateY(0deg)`};

  &.hoverable {
    &.hovering {
      transform: ${({ isCardOpen }) =>
        isCardOpen ? `rotateY(-180deg)` : `rotateY(-25deg)`};

      .scrap {
        opacity: 1;
        transition: opacity 0.25s ease;
      }
    }
    /* &:hover {

    } */
  }
  z-index: 2;
`;
const CardPageFrontOutSide = styled(CardPage)`
  position: absolute;
  -webkit-backface-visibility: hidden;

  border: 4px solid #fbfbfb;
  /* background:  */
  background-image: linear-gradient(
    45deg,
    #fff3f4 0%,
    #fff6f3 99%,
    #ffffff 100%
  );
  background-size: cover;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: auto;
  }
`;
const CardPageFrontInside = styled(CardPage)`
  position: absolute;
  -webkit-backface-visibility: hidden;
  background-color: white;
  border: 20px solid #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  transform: rotateY(-180deg);
  border-right: none !important;
  background-position: 0px 80px;
`;
const CardPageBack = styled(CardPage)`
  &.isHover {
    border: 20px solid #f5f5f5;
  }
  transition: all 0.5s ease;
  background-color: white;
  border: 20px solid #ebebeb;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  z-index: 1;
  border-left: none !important;
`;

const Scrap = styled(animated.div)<scrapProps>`
  position: absolute;
  z-index: 1;
  top: ${({ id }) => (id === 1 ? "-25%" : "80%")};
  right: ${({ id }) => (id === 1 ? "0px" : "0px")};

  background-image: ${({ imageSrc }) => `url(${imageSrc})`};
  background-size: contain;
  background-repeat: no-repeat;

  width: 100px;
  height: 100px;
  /* z-index: 10; */
  opacity: 0;
  transition: all 0.5s ease 0.2s;
  transform: ${({ transform }) => transform};
  transform-origin: center center;

  transform: ${({ id }) =>
    id === 2
      ? "rotate(0deg) translate(-10px, -30px);"
      : "rotate(0deg) translate(0px, 20px);"};

  &.active {
    opacity: 1;
    transform: ${({ id }) =>
      id === 2
        ? "rotate(-20deg) translate(80px, -10px);"
        : "rotate(20deg) translate(0px, -5px);"};
  }
`;
export const Card: FunctionComponent<ComponentProps> = (props) => {
  const {
    cardPageFrontOutsideComponent,
    cardPageBackComponent,
    openable = true,
    hoverable = false,
    isHovering = false,
    size = 300,
    step = 0,
    previewScrapsImgSrc = [],
    onClickFrontCover = () => {},
  } = props;
  const [isCardOpen, setIsCardOpen] = React.useState<Boolean>(false);
  const [isHover, setIsHover] = React.useState<Boolean>(false);
  const [isLaydown, setIsLaydown] = React.useState(false);

  const [localStep, setLocalStep] = React.useState(step);

  const [springProps, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));

  const handleCardOpen = () => {
    // if (openable && localStep !== 0) {
    //   setIsCardOpen(!isCardOpen);
    // }
    onClickFrontCover();
  };
  // this is for mobile auto show hovering state
  React.useEffect(() => {
    setIsHover(isHovering);
  }, [isHovering]);

  React.useEffect(() => {
    if (step === 2) {
      if (localStep === 0) {
        setIsLaydown(false);
        setTimeout(() => {
          setIsCardOpen(true);
        }, 1000);
      } else if (localStep === 1) {
        setIsCardOpen(true);
      }
    } else if (step === 1) {
      if (localStep === 0) {
        setIsLaydown(false);
      } else if (localStep === 2) {
        setIsCardOpen(false);
      }
    } else if (step === 0) {
      if (localStep === 1) {
        setIsLaydown(true);
      } else if (localStep === 2) {
        setIsCardOpen(false);
        setTimeout(() => {
          setIsLaydown(true);
        }, 600);
      }
    }
    setLocalStep(step);
  }, [step]);

  return (
    <React.Fragment>
      <CardWrapper
        cardSize={size}
        isCardOpen={isCardOpen}
        isLaydown={isLaydown}
        // TODO: add parallax effect
        onMouseMove={() => {}}
        className={`${isHover ? `isHover` : ""}`}
      >
        <CardPageFront
          className={`${hoverable ? `hoverable ${isHover ? `hovering` : ""}` : ""}`}
          onClick={handleCardOpen}
          cardSize={size}
          isCardOpen={isCardOpen}
          onMouseEnter={() => {
            if (hoverable) {
              setIsHover(true);
            }
          }}
          onMouseLeave={() => {
            if (hoverable) {
              setIsHover(false);
            }
          }}
        >
          <CardPageFrontOutSide cardSize={size} isCardOpen={isCardOpen}>
            {cardPageFrontOutsideComponent}
          </CardPageFrontOutSide>
          <CardPageFrontInside cardSize={size} isCardOpen={isCardOpen}>
            {/* {props?.cardPageFrontInsideComponent} */}
          </CardPageFrontInside>
        </CardPageFront>
        <CardPageBack
          isCardOpen={isCardOpen}
          cardSize={size}
          className={`${isHover ? `isHover` : ""}`}
        >
          {cardPageBackComponent}
        </CardPageBack>
        {/* some deco */}
        {previewScrapsImgSrc && (
          <React.Fragment>
            <Scrap
              // @ts-ignore
              // transform={springProps?.xy?.interpolate(trans1)}
              // style={{ transform: springProps?.xy?.interpolate(trans1) }}
              id={1}
              className={`scrap ${isHover ? "active" : ""}`}
              imageSrc={previewScrapsImgSrc?.[0]}
            ></Scrap>
            <Scrap
              // @ts-ignore
              // transform={springProps?.xy?.interpolate(trans2)}
              // style={{ transform: springProps?.xy?.interpolate(trans2) }}
              id={2}
              className={`scrap ${isHover ? "active" : ""}`}
              imageSrc={previewScrapsImgSrc?.[1]}
            ></Scrap>
          </React.Fragment>
        )}
      </CardWrapper>
    </React.Fragment>
  );
};

export default Card;
