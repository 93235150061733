import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { useRecoilState } from "recoil";
import Footer from "../components/footer";
import Header from "../components/header";
import Slider from "../components/slider";
import Env from "../components/env";
import Card from "./../components/card";
import * as Firebase from "./../service/firebase";
import ColorPicker from "./../components/colorPicker";
import { useHistory, useLocation } from "react-router-dom";
import useWindowSize from "./../hooks/useWindowSize";
import {
  stepsState,
  cardMainGraphSrcState,
  cardTextState,
  sliderImgSrcState,
} from "../store/atom";
import { sendAmplitudeData } from "../service/amplitude";
// import useCardsData from "../hooks/useCardsData";
import { srcJson } from "./../service/utils";
const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translate(0%, 10%);
  }
  100% {
    opacity: 1;
    transform: translate(0%, 0);
  }
`;

const CanvasWrapper = styled.div`
  width: 100%;
  height: calc(100% - 64px);
  padding: 64px;
  padding-bottom: 16px;
  background: var(--canvasBG);

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 24px;
  grid-column-gap: 24px;
  z-index: 0;

  @media only screen and (max-width: 768px) {
    padding: 16px;
    overflow: hidden;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`;
const CardWrapper = styled.div`
  grid-column: 3 / 4;
  grid-row: 2 / 3;
  transition: transform 0.3s ease;
  transform: translate(0px, 0px);

  &.shift-left {
    transform: translate(-50%, 0px);
  }
  @media only screen and (max-width: 768px) {
    grid-row: 3 / 4;
  }
`;
const SliderWrapper = styled.div`
  grid-column: 2 / 5;
  grid-row: 5 / 6;
  display: flex;
  justify-content: center;
  animation: ${fadeIn} 0.3s ease;

  @media only screen and (max-width: 768px) {
    grid-column: 1 / 6;
    grid-row: 6 / 7;
  }
`;
export const Editor = () => {
  let history = useHistory();
  let location = useLocation();
  const windowSize = useWindowSize();
  const [steps, setSteps] = useRecoilState(stepsState);
  const [text, setText] = useRecoilState(cardTextState);
  const [cardMainGraphSrc, setCardMainGraphSrcState] = useRecoilState(
    cardMainGraphSrcState
  );
  const [localSteps, setLocalSteps] = useState(steps);
  // const srcJson = useCardsData();

  const [isOpen, setIsOpen] = useState(false);
  const [isInvert, setIsInvert] = useState(false);
  // const [baseSize, setBaseSize] = React.useState(450);
  const [size, setSize] = React.useState({
    baseSize: 200,
    cardSize: 200 * 0.8,
  });
  const [isLoading, setIsloading] = React.useState(false);
  const [sliderImgSrc, setSliderImgSrc] = useRecoilState(sliderImgSrcState); // useState<string[]>([]);
  const [coverImgSrc, setCoverImgSrc] = useState<string | null>(null);
  React.useEffect(() => {
    if (localSteps === 1 && steps === 2) {
      setIsOpen(false);
      sendAmplitudeData("[Editor] Text info", {
        haveContent: text?.length > 0,
        wordCount: text?.length,
      });
      setTimeout(() => {
        setIsInvert(true);
      }, 600);
    }
    if (localSteps === 2 && steps === 1) {
      setIsOpen(true);
    }
    if (steps === 0) {
      setIsOpen(false);
    }
    if (localSteps === 0 && steps === 1) {
      setIsOpen(true);
    }
    setLocalSteps(steps);
  }, [steps, localSteps]);
  React.useEffect(() => {
    if (windowSize?.height && windowSize?.width) {
      let ratio = windowSize?.height / windowSize?.width > 1.5 ? 0.6 : 0.7;
      let _MaxCanvasHeight = windowSize?.height * ratio - 48; // 60 for bottom editor bar
      let _MaxCanvasWidth = (_MaxCanvasHeight * 4) / 6;
      setSize({
        baseSize: _MaxCanvasWidth * 1.4,
        cardSize: _MaxCanvasWidth * 0.8,
      });
    }
  }, [windowSize]);
  React.useEffect(() => {
    sendAmplitudeData("[Editor] Enter");
    const _state: any = location.state;

    const fetchRes = async (cardSrc: any) => {
      setIsloading(true);
      let coverSrcUrl = await Firebase.getImageSrc(
        `simpleGreetingSrc/${_state?.cardId}/base.png`
      );
      if (coverSrcUrl) {
        setCoverImgSrc(coverSrcUrl);
      }
      Promise.all(
        cardSrc?.mainSrc.map((elm: any) =>
          Firebase.getImageSrc(`simpleGreetingSrc/${_state?.cardId}/${elm}.png`)
        )
      ).then((values: any) => {
        setSliderImgSrc(values);
        setIsloading(false);
      });
    };

    if (_state && srcJson.length) {
      // get all scraps
      const cardSrc = srcJson.find((s) => s.id === _state?.cardId);
      if (cardSrc) {
        fetchRes(cardSrc);
      }
    }
  }, [location, srcJson]);
  const { baseSize, cardSize } = size;
  const isDesktop = () => {
    return windowSize.width > 768;
  };
  return (
    <React.Fragment>
      <Header />
      <CanvasWrapper>
        {!isLoading && (
          <CardWrapper
            className={steps === 2 ? (isDesktop() ? "shift-left" : "") : ""}
          >
            <Card
              previewCover={coverImgSrc}
              noAnimation={true}
              isInvert={false}
              top={-30}
              baseSize={baseSize}
              cardSize={cardSize}
              isEditing={true}
              isOpen={isOpen}
              frontPageOnClick={() => {
                if (steps === 0) {
                  setSteps(1);
                  sendAmplitudeData("[Editor] Next step", {
                    from: 1,
                    to: 2,
                    trigger: "card",
                  });
                }
                if (steps === 1) {
                  setSteps(0);
                  sendAmplitudeData("[Editor] Next step", {
                    from: 2,
                    to: 1,
                    trigger: "card",
                  });
                }
              }}
            />
            {steps === 2 && (
              <Env
                baseSize={baseSize * 0.8}
                bottom={0}
                top={isDesktop() ? baseSize / 4 : baseSize / 3}
                left={isDesktop() ? "150%" : "50%"}
              />
            )}
          </CardWrapper>
        )}
        {steps === 0 && (
          <SliderWrapper>
            <Slider
              imgSrc={sliderImgSrc}
              handleUpdate={(id: number) => {
                setCardMainGraphSrcState(sliderImgSrc[id]);
              }}
            />
          </SliderWrapper>
        )}
        {steps === 2 && (
          <SliderWrapper>
            <ColorPicker />
          </SliderWrapper>
        )}
      </CanvasWrapper>
      <Footer />
    </React.Fragment>
  );
};

export default Editor;
