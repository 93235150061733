import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);
export const db = firebase.database();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const analytics = firebase.analytics();

export const getAllSrc = () =>
  storage
    .ref(`simpleGreetingSrc`)
    .once("value")
    .then((snapshot) => snapshot.forEach((s) => {
      console.log('!!', s);
    }));

export const getImageSrc = (path) => storage.ref(path).getDownloadURL();