import React, { FunctionComponent } from "react";
import { useLocation } from "react-router-dom";
import Textarea from "@kiwicom/orbit-components/lib/Textarea";
import styled, { keyframes, createGlobalStyle, css } from "styled-components";

import CardCover from "./canvasComponent";
import LinkedElm from "./cardLinkElement";
import cover from "./../assets/xmas_cover.png";

import { useRecoilState } from "recoil";
import { cardTextState, fontFamilyState } from "../store/atom";
import FontPicker from "./fontPicker";

interface CardProps {
  isInvert: boolean;
  isOpen?: boolean;
  top?: Number | null;
  isEditing: boolean;
  frontPageOnClick: () => void;
  previewCover?: string | null;
  noAnimation?: boolean;
  cardSize?: number;
  baseSize?: number;
  demo?: boolean;
  fontFamily?: string;
}
interface StyledProps {
  isOpened: boolean | undefined;
  isInvert?: boolean;
  top?: Number | null;
  onClick?: any;
  previewCover?: string | null;
  cardSize?: number | undefined;
  baseSize?: number | undefined;
  demo?: boolean;
  noAnimation?: boolean;
}
interface cardBaseProps {
  cardSize?: number | undefined;
  baseSize?: number | undefined;
  demo?: boolean;
}
interface TextareaWrapperProps {
  isEditing?: boolean;
  fontFamily?: string;
}

const GlobalStyle = createGlobalStyle<cardBaseProps>`
  .card {
    width: ${({ cardSize }) => cardSize && `${cardSize}px`};
    height: ${({ cardSize }) => cardSize && `${(cardSize * 6) / 4}px`};
  }
  .cart-page-outside,
  .cart-page-inside {
    position: absolute;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }
  .cart-page-inside,
  .cart-page-back {
    background-color: white;
    border: 10px solid #f0f0f0;
    display: box;
    display: flex;
    box-pack: center;
    justify-content: center;
    box-align: center;
    align-items: center;
    text-align: center;
  }
`;

const CardOut = keyframes`
  0% {
    opacity: 1;
    transform: scale(1) rotate(0deg) translate(-50%, 100%) rotateZ(90deg);
  }
  40% {
    opacity: 1;
    transform: scale(1) rotate(0deg) translate(-50%, 20%) rotateZ(90deg);
  }
  100% {
    opacity: 1;
      transform: scale(1) rotate(0deg) translate(-50%, 0%) rotateZ(0deg);
  }
`;
const CardOutMobile = keyframes`
  0% {
    opacity: 1;
    transform: scale(1) rotate(0deg) translate(-50%, 100%) rotateZ(90deg);
  }
  40% {
    opacity: 1;
    transform: scale(1) rotate(0deg) translate(-50%, 20%) rotateZ(90deg);
  }
  100% {
    opacity: 1;
    transform: scale(1) rotate(0deg) translate(-50%, -10%) rotateZ(0deg);
  }
`;
const CardIn = keyframes`
  0% {
    transform: scale(1) rotate(0deg) translate(-50%, 0) rotateZ(0deg);
  }
  20% {
    transform: scale(1) rotate(0deg) translate(-50%, 0) rotateZ(0deg);
  }
  50% {
    transform: scale(1) rotate(0deg) translate(-50%, 40%) rotateZ(90deg);
  }
  100% {
    transform: scale(1) rotate(0deg) translate(-50%, 100%) rotateZ(90deg);
  }
`;

const IndicatorAnimate = keyframes`
  0% { left: 100px; top:0; opacity: 1}
  /* tap */  
  1% { left: 100px; top:0; opacity: 1; transform: scale(1)}
  50% { left: 100px; top:0; opacity: 1; transform: scale(0.8)}
  100% { left: 100px; top:0; opacity: 1; transform: scale(1)}

  /*  to left */
  /* 60% { left: 100px; top:0; opacity: 1}
  61% { left: 100px; top:0; width: 30px; height: 30px; border-radius: 50%;}
  65% { width: 60px; top: 15px; height: 8px; border-radius: 35%;} */

  /*  fade out */
  /* 75% { left: 0; top: 0px; width: 30px; height: 30px; opacity: 0.5; border-radius: 50%;}
  100% { left: 0px; top: 0px; width: 30px; height: 30px; opacity: 0;} */
`;

const Indicator = styled.div`
  width: 120px;
  height: 30px;
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(0, -50%);
  margin: 0px -120px;

  > div#dot {
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 50%;
    position: absolute;
    animation: ${IndicatorAnimate} 1s linear infinite;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  }
`;
const CardWrapper = styled.div<StyledProps>`
  /* -webkit-transform: ${({ isOpened, isInvert }) =>
    isInvert
      ? `scale(1) rotate(0deg) translate(-50%, 100%) rotateZ(90deg)`
      : isOpened
      ? `scale(1) translate(-50% + 175px, 0) rotateZ(0deg)`
      : `rotate(0deg) translate(-50%, 0) rotateZ(0deg)`}; */
  transform: ${({ isOpened, isInvert }) =>
    isInvert
      ? `scale(1) rotate(0deg) translate(-50%, 100%) rotateZ(90deg)`
      : isOpened
      ? `scale(1) translate(-50% + 175px, 0) rotateZ(0deg)`
      : `rotate(0deg) translate(-50%, 0) rotateZ(0deg)`};

  @media only screen and (max-width: 768px) {
    /* -webkit-transform: ${({ isOpened, isInvert }) =>
      isInvert
        ? `scale(1) rotate(0deg) translate(-50%, 100%) rotateZ(90deg)`
        : isOpened
        ? `scale(1.6) translate(-30%, -25%) rotateZ(0deg)`
        : `rotate(0deg) translate(-50%, 0) rotateZ(0deg)`}; */
    transform: ${({ isOpened, isInvert }) =>
      isInvert
        ? `scale(1) rotate(0deg) translate(-50%, 100%) rotateZ(90deg)`
        : isOpened
        ? `scale(1.4) translate(-30%, -15%) rotateZ(0deg)`
        : `rotate(0deg) translate(-50%, -10%) rotateZ(0deg)`};
  }
  top: ${({ top, baseSize }) =>
    top ? `${top}px` : baseSize ? `${-baseSize}px` : 0};
  left: 50%;
  z-index: 1;
  width: ${({ cardSize, demo }) =>
    cardSize && `${cardSize + (demo ? 4 : 0)}px`};
  height: ${({ cardSize }) => cardSize && `${(cardSize * 6) / 4}px`};

  perspective: 1500px;
  position: absolute;
  -webkit-transition: -webkit-transform 0.6s ease-out;
  transition-property: transform;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.68, -0.25, 0.265, 1.25);
  transform-origin: center center;
  /* transition-delay: initial; */

  &.animate {
    animation: ${({ isInvert }) =>
      css`
        ${isInvert ? CardIn : CardOut} ${isInvert
          ? "3s"
          : "2s"} alternate cubic-bezier(0.87, -0.11, 0.3, 1) 0.01s;
      `};

      @media only screen and (max-width: 768px) {
        animation: ${({ isInvert }) =>
          css`
            ${isInvert ? CardIn : CardOutMobile} ${isInvert
              ? "3s"
              : "2s"} alternate cubic-bezier(0.87, -0.11, 0.3, 1) 0.01s;
          `};
      }
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    left: ${({ isOpened }) => (isOpened ? "-100%" : 0)};
    width: ${({ isOpened }) => (isOpened ? "200%" : "100%")};
    background: transparent;
    top: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    filter: blur(50px);
    // box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
    transition: all 0.5s cubic-bezier(0.87, -0.11, 0.3, 1);
    ${({ isOpened }) =>
      isOpened ? "transition-delay: 0.3s" : "transition-delay: 0.1s"};
  }
`;
const CardPage = styled.div<cardBaseProps>`
  width: ${({ cardSize, demo }) =>
    cardSize && `${cardSize + (demo ? 4 : 0)}px`};
  height: ${({ cardSize }) => cardSize && `${(cardSize * 6) / 4}px`};
  transition: transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
  cursor: pointer;
  position: absolute;
  outline: 1px solid transparent;
`;
const CardPageFront = styled(CardPage)<StyledProps>`
  transform-origin: 0 50% 0;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transform: ${({ isOpened }) =>
    isOpened ? `rotateY(-180deg)` : `rotateY(0deg)`};
  -webkit-transform: ${({ isOpened }) =>
    isOpened ? `rotateY(-180deg)` : `rotateY(0deg)`};
  z-index: 2;
`;
const CardPageFrontOutSide = styled(CardPage)`
  border: 4px solid #fbfbfb;
  z-index: 1;
  /* background: url(${cover}) no-repeat center; */
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
`;
const CardPageFrontInside = styled(CardPage)`
  transform: rotateY(-180deg);
  border-right: none !important;
  background-position: 0px 80px;
`;
const CardPageBack = styled(CardPage)<StyledProps>`
  z-index: 1;
  border-left: none !important;
  cursor: initial;
  p {
    font-size: 2rem;
    font-weight: 600;
  }
`;
const TextareaWrapper = styled.div<TextareaWrapperProps>`
  width: 100%;
  height: 100%;
  padding: ${({ isEditing }) => (isEditing ? "8px" : "20px")};
  overflow-y: scroll;

  white-space: pre-wrap;
  * {
    white-space: pre-wrap;
  }
  > label {
    width: 100%;
    height: 100%;
    min-height: 100%;
    margin-bottom: 0px !important;
    > textarea {
      width: 100%;
      height: 100% !important;
      box-sizing: border-box;
      /* padding: 0 !important; */
      border: none;
      overflow: auto;
      outline: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;

      resize: none; /*remove the resize handle on the bottom right*/

      font-size: 20px;
      line-height: 1.5;
      font-family: ${({ fontFamily }) => fontFamily};

      @media only screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
  // for public formatting
  p,
  pre,
  code {
    text-align: left;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
    font-family: ${({ fontFamily }) => fontFamily};
    margin-bottom: 20px;
    white-space: pre-wrap;
    word-wrap: break-word;
    color: #252a31;
    @media only screen and (max-width: 768px) {
      font-size: 14px;
    }
    a {
      cursor: pointer;
      font-size: 20px;
      line-height: 1.5;
      font-weight: 600;
      font-family: ${({ fontFamily }) => fontFamily};
      word-break: break-word;
      color: #50514f;
      @media only screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
`;
const FontPickerWrapper = styled.div`
  position: absolute;
  overflow: visible;
  bottom: -10px;
  right: -10px;
  z-index: 2;
`;
export const Card: FunctionComponent<CardProps> = (props) => {
  const location = useLocation();
  const [showIndicator, setShowIndicator] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [isInvert, setIsInvert] = React.useState<boolean>(true);

  const [text, setText] = useRecoilState(cardTextState);
  const [fontFamily, setFontFamily] = useRecoilState(fontFamilyState);
  const demoContent = `Dear Dad & Mom,
  Merry Christmas!
  Can’t wait for the Xmas present! 
  
  Luv,
  👧`;
  const {
    isEditing,
    frontPageOnClick,
    previewCover = null,
    noAnimation = true,
    cardSize = 500 * 0.8,
    baseSize = 500,
    demo = false,
  } = props;
  const ifPublic = (str: string) => str?.split("/")?.[1] === "c";
  const showImage = () => {
    return (
      location.pathname.includes("gallery") || ifPublic(location?.pathname)
    );
  };
  const isShare = () => {
    return (
      location.pathname.includes("preview") ||
      ifPublic(location?.pathname) ||
      demo === true
    );
  };
  React.useEffect(() => {
    setIsInvert(props.isInvert);
    setIsOpen(!!props.isOpen);
  }, [props]);

  React.useEffect(() => {
    if (isShare()) {
      setTimeout(() => {
        setShowIndicator(true);
      }, 2000);
    }
  }, [isShare()]);

  return (
    <React.Fragment>
      {/* Global Styling */}
      <GlobalStyle baseSize={baseSize} cardSize={cardSize} />
      {/* Main Card */}
      <CardWrapper
        className={`${!noAnimation ? "animate" : ""}`}
        isOpened={isOpen}
        isInvert={isInvert}
        top={props?.top || null}
        baseSize={baseSize}
        cardSize={cardSize}
        demo={demo}
        onMouseMove={() => setShowIndicator(false)}
      >
        {/* Indicator */}
        {showIndicator && (
          <Indicator>
            <div id="dot" />
          </Indicator>
        )}
        {/* Front */}
        <CardPageFront
          onClick={
            !isInvert
              ? () => {
                  frontPageOnClick();
                  setIsOpen(!isOpen);
                }
              : null
          }
          isOpened={isOpen}
          className="card-page cart-page-front"
          baseSize={baseSize}
          cardSize={cardSize}
        >
          <CardPageFrontOutSide
            baseSize={baseSize}
            cardSize={cardSize}
            className="card-page cart-page-outside"
          >
            {showImage() && previewCover ? (
              <img src={previewCover} alt={"digital card cover"} />
            ) : (
              <CardCover
                cardSize={cardSize}
                baseSrc={previewCover}
                onTap={
                  !isInvert
                    ? () => {
                        frontPageOnClick();
                        setIsOpen(!isOpen);
                      }
                    : null
                }
              />
            )}
          </CardPageFrontOutSide>
          <CardPageFrontInside
            baseSize={baseSize}
            cardSize={cardSize}
            className="card-page cart-page-inside"
          >
            <span className="merry-christmas"></span>
          </CardPageFrontInside>
        </CardPageFront>
        {/* Back */}
        <CardPageBack
          baseSize={baseSize}
          cardSize={cardSize}
          // onClick={(e: Event) => e.stopPropagation()}
          onClick={
            !isInvert && !isEditing
              ? () => {
                  frontPageOnClick();
                  setIsOpen(!isOpen);
                }
              : null
          }
          isOpened={isOpen}
          className="card-page cart-page-back"
        >
          <TextareaWrapper isEditing={isEditing} fontFamily={fontFamily}>
            {isEditing ? (
              <Textarea
                placeholder={`Dear Dad & Mom,\n\nMerry Christmas! Can’t wait for the Xmas present! \n\n Luv,\n👧`}
                readOnly={!isEditing}
                value={text}
                onChange={(e: any) => setText(e?.currentTarget?.value)}
              />
            ) : (
              // ✅ Renderers prop takes each paragraph and places <Linkify></Linkify>
              <LinkedElm>{demo ? demoContent : text}</LinkedElm>
            )}
          </TextareaWrapper>
        </CardPageBack>
        <FontPickerWrapper>
          <FontPicker
            apiKey={process.env.REACT_APP_API_KEY as string}
            show={isEditing && isOpen}
            limit={6}
            families={[
              "Caveat",
              "Cedarville Cursive",
              "Cookie",
              "Dancing Script",
              "Reenie Beanie",
              "Dawning of a New Day",
            ]}
            activeFontFamily={fontFamily}
            onChange={setFontFamily}
          />
        </FontPickerWrapper>
      </CardWrapper>
    </React.Fragment>
  );
};
export default Card;
