import React from "react";
import "./App.css";
import {
  Route,
  Redirect,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import getTokens from "@kiwicom/orbit-components/lib/getTokens";
import ThemeProvider from "@kiwicom/orbit-components/lib/ThemeProvider";
import Gallery from "./page/Gallery";
import Editor from "./page/Editor";
import Preview from "./page/Preview";

import { useResetRecoilState, useRecoilState } from "recoil";
import {
  stepsState,
  cardTextState,
  cardBgState,
  cardIdState,
  cardMainGraphState,
  cardMainGraphSrcState,
  canvasToImageBase64State,
  envFilterState,
  fontFamilyState,
} from "./store/atom";

import { sendAmplitudeData } from "./service/amplitude";

const routes = [
  { path: "/gallery", name: "Gallery", Component: Gallery },
  { path: "/editor/:id", name: "Editor", Component: Editor },
  { path: "/preview/:id", name: "Preview", Component: Preview },
  { path: "/share/:id", name: "Share", Component: Preview },
  { path: "/c/:id", name: "Public", Component: Preview },
];

function App() {
  const h = useHistory();
  const l = useLocation();
  const resetStep = useResetRecoilState(stepsState);
  const resetText = useResetRecoilState(cardTextState);
  const resetEnv = useResetRecoilState(envFilterState);
  const resetFont = useResetRecoilState(fontFamilyState);

  const [t, setT] = useRecoilState(cardTextState);
  const [b, setB] = useRecoilState(cardBgState);
  const [cardId, setCardId] = useRecoilState(cardIdState);
  //
  const [g, setG] = useRecoilState(cardMainGraphState);
  const [gs, setGs] = useRecoilState(cardMainGraphSrcState);
  const [base64, setbase64] = useRecoilState(canvasToImageBase64State);
  const [env, setEnv] = useRecoilState(envFilterState);
  const customTokens = getTokens({
    palette: {
      product: {
        light: "#f0f7ff",
        lightHover: "#dfecff",
        lightActive: "#cee8ff",
        normal: "#516778",
        normalHover: "#5d7e97",
        normalActive: "#516778",
        dark: "#110013",
      },
    },
  });
  React.useEffect(() => {
    let feedbackBtn = window.document.getElementById("emojicom-trigger");
    let bodyDOM = document.getElementsByTagName("BODY")[0];
    // remove the feedback button
    if (feedbackBtn) {
      if (l?.pathname?.includes("editor")) {
        feedbackBtn.style.display = "none";
      } else {
        feedbackBtn.style.display = "block";
      }
    }
    // remove text
    if (l?.pathname?.includes("gallery")) {
      resetText();
      resetEnv();
      resetStep();
      resetFont();
      bodyDOM.style.overflow = "auto";
    } else {
      bodyDOM.style.overflow = "hidden";
    }
  }, [l]);
  React.useEffect(() => {
    window.addEventListener("click", (event) => {
      try {
        if (
          event?.target?.className &&
          event?.target?.className?.includes("launcher")
        ) {
          sendAmplitudeData("[General] Tap on Feeback", {
            from: window.location.pathname,
          });
        }
      } catch (e) {
        console.log(e);
      }
    });
  }, []);
  React.useEffect(() => {
    if (
      l?.pathname?.includes("editor") ||
      l?.pathname?.includes("share") ||
      l?.pathname?.includes("preview")
    ) {
      h.push("/gallery", {});
    }
  }, []);
  // React.useEffect(() => {
  //   console.log(
  //     "\ncardTextState:",
  //     t,
  //     "\ncardIdState",
  //     cardId,
  //     "\ncardMainGraphState",
  //     g,
  //     "\ncardMainGraphSrcState",
  //     gs,
  //     "\nenvFilterState",
  //     env
  //   );
  // }, [t, b, cardId, g, gs, env]);
  return (
    <ThemeProvider theme={{ orbit: customTokens }}>
      <div className="App">
        <Switch>
          {routes.map(({ path, Component }) => (
            <Route key={path} exact path={path}>
              <Component />
            </Route>
          ))}
          <Redirect to="/gallery" />
        </Switch>
      </div>
    </ThemeProvider>
  );
}

export default App;
