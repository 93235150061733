import React, { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import useClipboard from "react-use-clipboard";
import useWindowSize from "./../hooks/useWindowSize";
import { sendAmplitudeData } from "../service/amplitude";
import Wizard, { WizardStep } from "@kiwicom/orbit-components/lib/Wizard";
import ListChoice from "@kiwicom/orbit-components/lib/ListChoice";
import Button from "@kiwicom/orbit-components/lib/Button";
import Popover from "@kiwicom/orbit-components/lib/Popover";
import Facebook from "@kiwicom/orbit-components/lib/icons/Facebook";
import Twitter from "@kiwicom/orbit-components/lib/icons/Twitter";
import QrCode from "@kiwicom/orbit-components/lib/icons/QrCode";
import Link from "@kiwicom/orbit-components/lib/icons/Link";

import ChevronUp from "@kiwicom/orbit-components/lib/icons/ChevronUp";
import ChevronLeft from "@kiwicom/orbit-components/lib/icons/ChevronLeft";
import Stack from "@kiwicom/orbit-components/lib/Stack";
import styled from "styled-components";
import * as Firebase from "./../service/firebase";

import { useRecoilState } from "recoil";
import {
  stepsState,
  cardTextState,
  envFilterState,
  canvasToImageBase64State,
  cardIdState,
  cardMainGraphState,
  fontFamilyState,
} from "../store/atom";

const StyledFooter = styled.div`
  z-index: 2;
  top: inherit;
  position: fixed;
  bottom: 0px;
  left: 0px;
  height: 64px;
  width: inherit;

  padding: 12px;
  display: flex;
  align-items: center;
  border-top: 1px solid;
  border-top-color: var(--border);
  background-color: var(--bg);

  > div {
    width: inherit;
  }
`;
const WizardWrapper = styled.div`
  position: absolute;
  width: 250px;
  height: auto;
  @media only screen and (max-width: 768px) {
    /* position: relative; */
    width: 170px;
  }
  /* remove progress bar extra styling */
  > nav {
    li {
      &:first-child {
        div:first-child {
          &::before {
            display: none;
          }
        }
      }
      &:last-child {
        div:first-child {
          &::after {
            display: none;
          }
        }
      }
    }
  }
`;

export const Footer = () => {
  let history = useHistory();
  const location = useLocation();
  const params = useParams();
  const windowSize = useWindowSize();
  const [showShareMenu, setShowShareMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCopied, setCopied] = useClipboard(
    `${window.location.href.replace("/share/", "/c/")}?utm_source=share_link`,
    {
      successDuration: 1500,
    }
  );
  const [prevLocation, setPrevLocation] = useState("");
  const [steps, setSteps] = useRecoilState(stepsState);
  const [cardId, setCardId] = useRecoilState(cardIdState);
  const [cardText, setCardText] = useRecoilState(cardTextState);
  const [cardEnv, setCardEnv] = useRecoilState(envFilterState);
  const [cardMainGraph, setCardMainGraph] = useRecoilState(cardMainGraphState);
  const [canvasToImageBase64, setCanvasToImageBase64] = useRecoilState(
    canvasToImageBase64State
  );
  const [fontFamily, setFontFamily] = useRecoilState(fontFamilyState);
  React.useEffect(() => {
    if (location.state?.from) {
      setPrevLocation(location.state?.from);
    }
  }, [location]);

  const handleNextStep = async () => {
    if (steps === 2) {
      setIsLoading(true);
      // upload to firebase and go to share page.
      const data = {
        dataURL: canvasToImageBase64,
        card: {
          id: cardId,
          mainGraphicId: cardMainGraph,
        },
        content: cardText,
        envelop: {
          hue: cardEnv,
        },
        font: {
          family: fontFamily,
        },
      };
      let collageKey = params?.id; // collageKey
      const { dataURL = "", ...cardData } = data;
      // upload finish card image to Storage
      await Firebase.db
        .ref(`/${process.env.REACT_APP_DB_ENDPOINT}/${collageKey}`)
        .once("value")
        .then(async (snapshot) => {
          // process image
          await Firebase.storage
            .ref(`/${process.env.REACT_APP_DB_ENDPOINT}/${collageKey}/imageSrc`)
            .child("imageSrc")
            .putString(dataURL, "data_url", { contentType: "image/png" })
            .then(async (res) => {
              if (res?.state === "success") {
                // update metadata
                const updateData = {
                  ...snapshot.val(),
                  ...cardData,
                  updateDate: new Date().getTime() / 1,
                  imageSrc: {
                    path: `https://firebasestorage.googleapis.com/v0/b/pickcollage.appspot.com/o/${process.env.REACT_APP_DB_ENDPOINT}%2F${collageKey}%2FimageSrc%2FimageSrc?alt=media`,
                  },
                };
                await Firebase.db.ref().update({
                  [`/${process.env.REACT_APP_DB_ENDPOINT}/${collageKey}`]: updateData,
                });
                setIsLoading(false);
                sendAmplitudeData("[Editor] Done", { collageId: collageKey });
                history.push({
                  pathname: `/share/${collageKey}`,
                });
              }
            });
        });
    }
    const newSteps = steps + 1 > 2 ? 2 : steps + 1;
    sendAmplitudeData("[Editor] Next step", {
      from: steps + 1,
      to: newSteps + 1,
      trigger: "button",
    });
    setSteps(newSteps);
  };
  const handleBackStep = () => {
    const newSteps = steps - 1 < 0 ? 0 : steps - 1;
    sendAmplitudeData("[Editor] Back step", {
      from: steps + 1,
      to: newSteps + 1,
      trigger: "button",
    });
    setSteps(newSteps);
  };
  const toggleShareMenu = () => {
    setShowShareMenu(!showShareMenu);
    sendAmplitudeData("[Share] Toggle menu");
  };
  const handleCreateNew = () => {
    history.push({
      pathname: `/gallery`,
    });
    sendAmplitudeData("[General] Navigate", {
      from: location.pathname,
      to: "gallery",
      trigger: "create new button",
    });
  };
  const handleActionButton = async () => {
    if (isSharing() || isPublic()) {
      sendAmplitudeData(`[${isSharing() ? `Editor` : `Share`}] Link Copied`);
      setCopied(true);
      return;
    }
    if (isPreview()) {
      if (prevLocation === "share") {
        sendAmplitudeData("[Editor] Done");
        history.goBack();
        return;
      }
      // setSteps(steps);
      sendAmplitudeData("[Editor] To Editor");
      history.push({
        pathname: `/editor/${params?.id}`,
        state: {
          cardId: cardId,
        },
      });
      if (steps === 1) {
        setSteps(0);
        setTimeout(() => {
          setSteps(1);
        }, 100);
      }
    }
  };

  const isEditing = () => {
    return location.pathname.includes("editor");
  };
  const isSharing = () => {
    return location.pathname.includes("share");
  };
  const isPreview = () => {
    return location.pathname.includes("preview");
  };
  const isPublic = () => {
    return location?.pathname?.split("/")?.[1] === "c";
  };
  return (
    <StyledFooter>
      <Stack direction="row" align="center" justify="between">
        {isEditing() && windowSize?.width > 768 && (
          <WizardWrapper>
            <Wizard
              id="wizard"
              completedSteps={steps}
              activeStep={steps}
              onChangeStep={(e) => {
                sendAmplitudeData("[Editor] Tap Steps", {
                  from: steps + 1,
                  to: e + 1,
                });
                setSteps(e);
              }}
            >
              <WizardStep title="Design" />
              <WizardStep title="Message" />
              <WizardStep title="Share" />
            </Wizard>
          </WizardWrapper>
        )}
        {/* <div></div> */}
        {isEditing() ? (
          <Stack
            direction="row"
            align="center"
            justify={windowSize?.width > 768 ? "center" : "center"}
          >
            {steps > 0 && (
              <Button
                type="secondary"
                width={windowSize?.width > 768 ? "100px" : "100px"}
                iconLeft={<ChevronLeft />}
                onClick={handleBackStep}
              >
                Back
              </Button>
            )}
            <Button
              type="primary"
              width={windowSize?.width > 768 ? "200px" : "100px"}
              onClick={handleNextStep}
            >
              {steps >= 2 ? (isLoading ? "Loading..." : "Done") : "Next"}
            </Button>
          </Stack>
        ) : isPreview() ? (
          <Stack direction="row" align="center" justify={"center"}>
            <Button type="primary" width={"200px"} onClick={handleActionButton}>
              {`Back${prevLocation === "share" ? "" : " to Editor"}`}
            </Button>
          </Stack>
        ) : isSharing() ? (
          <Stack direction="row" align="center" justify={"center"}>
            <Button type="primary" width={"200px"} onClick={handleActionButton}>
              {isCopied ? "Link copied! ✨" : "Share your card!"}
            </Button>
          </Stack>
        ) : (
          <Stack direction="row" align="center" justify={"center"}>
            <Button type="primary" width={"160px"} onClick={handleCreateNew}>
              Create New Card
            </Button>

            <Popover
              noPadding
              content={
                <div>
                  <ListChoice
                    description={null}
                    icon={<Facebook />}
                    onClick={() => {
                      sendAmplitudeData(`[Share] FB Channel`);
                      const text = encodeURIComponent(
                        "Spreading holiday cheer far + near"
                      );
                      const url = encodeURIComponent(window.location.href);
                      window.open(
                        `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${text}`,
                        "_blank"
                      );
                    }}
                    title="Facebook"
                  />
                  <ListChoice
                    description={null}
                    icon={<Twitter />}
                    onClick={() => {
                      sendAmplitudeData(`[Share] Twitter Channel`);
                      const text = encodeURIComponent(
                        "Spreading holiday cheer far + near 👉"
                      );
                      const url = encodeURIComponent(window.location.href);
                      const salut = encodeURIComponent("#salutcards");
                      window.open(
                        `https://twitter.com/intent/tweet?text=${text} ${url} ${salut}`,
                        "_blank"
                      );
                    }}
                    title="Twitter"
                  />
                  <ListChoice
                    description={null}
                    icon={<Link />}
                    onClick={() => {
                      sendAmplitudeData(`[Share] Link Copied`);
                      setCopied(true);
                    }}
                    title={isCopied ? "Copied! 🎉" : "Copy Link"}
                  />
                </div>
              }
              preferredPosition="top"
            >
              <Button
                type="secondary"
                width={"80px"}
                // iconRight={<ChevronUp />}
                onClick={toggleShareMenu}
              >
                Share
              </Button>
            </Popover>
          </Stack>
        )}
        {/* <div></div> */}
      </Stack>
    </StyledFooter>
  );
};

export default Footer;
