import React, { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import Footer from "../components/footer";
import Header from "../components/header";
import Env from "../components/env";
import Card from "../components/card";
import styled from "styled-components";
import * as Firebase from "./../service/firebase";
import useWindowSize from "./../hooks/useWindowSize";
import UncontrolledLottie from "./../components/cardLoader";
import { sendAmplitudeData } from "../service/amplitude";
import Confetti from "./../service/react-confetti/ReactConfetti";

import {
  cardTextState,
  envFilterState,
  canvasToImageBase64State,
  cardIdState,
  fontFamilyState,
} from "../store/atom";
import useCardsData from "../hooks/useCardsData";
import { srcJson } from "./../service/utils";

// const baseSize = 500;
const CanvasWrapper = styled.div`
  width: 100%;
  height: calc(100% - 64px);
  padding: 64px;
  background: var(--canvasBG);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;

  overflow: hidden;
  position: relative;
}
`;

export const Preview = () => {
  const location = useLocation();
  const params = useParams();
  const windowSize = useWindowSize();
  const history = useHistory();
  const [isLoading, setIsloading] = useState(true);
  const [cardText, setCardText] = useRecoilState(cardTextState);
  const [cardEnv, setCardEnv] = useRecoilState(envFilterState);
  const [cardId, setCardId] = useRecoilState(cardIdState);
  const [category, setCategory] = useState<string | null>(null);
  const [canvasToImageBase64, setCanvasToImageBase64] = useRecoilState(
    canvasToImageBase64State
  );
  const [fontFamily, setFontFamily] = useRecoilState(fontFamilyState);
  // const [baseSize, setBaseSize] = React.useState(500);
  const [size, setSize] = React.useState({
    baseSize: 200,
    cardSize: 200 * 0.8,
  });
  const { width, height } = useWindowSize();
  const [localParams, setLocalParams] = React.useState<any>();
  const [previewCover, setPreviewCover] = useState(null);
  // const srcJson = useCardsData();
  const isPreview = () => {
    return location.pathname.includes("preview");
  };
  const isPublic = () => {
    return location?.pathname?.split("/")?.[1] === "c";
  };
  const handleCardOpen = () => {
    if (params) {
      const _id = (params as any)?.id;
      sendAmplitudeData("[Share] Open Card", {
        collageId: _id,
      });
    }
  };
  const generateSnowAmount = () => {
    return (width * height) / 20000;
  };

  React.useEffect(() => {
    if (isPublic()) {
      if (params) {
        const _id = (params as any)?.id;
        sendAmplitudeData("[Share] Enter", {
          collageId: _id,
        });
      }
    }
  }, [location, params]);
  React.useEffect(() => {
    if (windowSize?.height && windowSize?.width) {
      let ratio = windowSize?.height / windowSize?.width > 1.5 ? 0.6 : 0.7;
      let _MaxCanvasHeight = windowSize?.height * ratio - 48; // 60 for bottom editor bar
      let _MaxCanvasWidth = (_MaxCanvasHeight * 4) / 6;
      setSize({
        baseSize: _MaxCanvasWidth * 1.4,
        cardSize: _MaxCanvasWidth * 0.8,
      });
    }
  }, [windowSize]);
  React.useEffect(() => {
    if (srcJson.length) {
      if (isPreview() && cardId > 0) {
        const fetchRes = async () => {
          setIsloading(true);
          let coverSrcUrl = await Firebase.getImageSrc(
            `simpleGreetingSrc/${cardId}/base.png`
          );
          if (coverSrcUrl) {
            setPreviewCover(coverSrcUrl);
            setIsloading(false);
          }
        };
        // console.log(cardId, params);

        if (params) {
          // set confetti
          const cardData = srcJson.find((card) => card.id === cardId);
          if (cardData && cardData?.tag) {
            setCategory(cardData?.tag);
          }
          // fetch resource
          const _id = (params as any)?.id;
          if (_id && _id !== localParams?.id) {
            setLocalParams(params);
            fetchRes();
          }
        }
      } else if (params) {
        const _id = (params as any)?.id;
        if (_id && _id !== localParams?.id) {
          setLocalParams(params);
          setIsloading(true);
          Firebase.db
            .ref(`${process.env.REACT_APP_DB_ENDPOINT}/${_id}`)
            .once("value")
            .then((snapshot) => {
              const data = snapshot.val();

              if (!data) {
                // redirect
                history.push("/");
              }
              if (!data?.["imageSrc"]?.path) {
                setIsloading(false);
                return;
              }
              if (data?.envelop?.hue) {
                setCardEnv(data?.envelop?.hue);
              }
              if (data?.content) {
                setCardText(data?.content);
              }
              if (data?.font?.family) {
                setFontFamily(data?.font?.family);
              }
              if (data?.["imageSrc"]?.path) {
                setPreviewCover(data?.["imageSrc"]?.path);
              }
              if (data?.card?.id) {
                const cardData = srcJson.find(
                  (card) => card.id === data?.card?.id
                );
                if (cardData && cardData?.tag) {
                  setCategory(cardData?.tag);
                }
              }
              setIsloading(false);
            })
            .finally(() => {
              setIsloading(false);
            });
        } else {
          // setIsloading(false);
        }
      }
    }
  }, [params, srcJson]);
  const { baseSize, cardSize } = size;
  console.log(category);

  return (
    <React.Fragment>
      <Header />
      <CanvasWrapper>
        {isLoading ? (
          <UncontrolledLottie></UncontrolledLottie>
        ) : (
          <React.Fragment>
            <Env baseSize={baseSize} bottom={-baseSize / 3} left={"50%"}>
              <Card
                noAnimation={false}
                isInvert={isPreview() || isPublic() ? false : true}
                top={-baseSize * 0.9}
                cardSize={cardSize}
                baseSize={baseSize}
                isEditing={false}
                frontPageOnClick={handleCardOpen}
                previewCover={previewCover}
              />
            </Env>
            {/* <Snowfall color="white" snowflakeCount={generateSnowAmount()} /> */}
            {category === "valentine" ? (
              <Confetti
                style={{
                  zIndex: -1,
                  transform: "rotate(180deg)",
                }}
                numberOfPieces={20}
                width={width}
                height={height}
                gravity={0.03}
                wind={0}
                tweenDuration={2500}
                rotateDeltaLevel={1}
                colors={["#f44336", "#ffffff", "#ff92e0", "#FF5722"]}
                drawShape={(ctx) => {
                  ctx.scale(0.35, 0.35);
                  ctx.beginPath();
                  ctx.moveTo(75, 40);
                  ctx.bezierCurveTo(75, 37, 70, 25, 50, 25);
                  ctx.bezierCurveTo(20, 25, 20, 62.5, 20, 62.5);
                  ctx.bezierCurveTo(20, 80, 40, 102, 75, 120);
                  ctx.bezierCurveTo(110, 102, 130, 80, 130, 62.5);
                  ctx.bezierCurveTo(130, 62.5, 130, 25, 100, 25);
                  ctx.bezierCurveTo(85, 25, 75, 37, 75, 40);
                  ctx.fill();
                }}
              />
            ) : category === "xmas" ? (
              <Confetti
                style={{
                  zIndex: -1,
                  transform: "rotate(0deg)",
                }}
                numberOfPieces={85}
                width={width}
                height={height}
                gravity={0.03}
                wind={0.01}
                colors={["#fff", "#efefef", "#b6e7ff"]}
                drawShape={(ctx) => {
                  ctx.beginPath();
                  const size = 8;
                  ctx.arc(size, size, size / 2, 0, 2 * Math.PI);
                  ctx.stroke();
                  ctx.closePath();
                  ctx.fill();
                }}
              />
            ) : (
              <Confetti
                style={{
                  zIndex: -1,
                  transform: "rotate(0deg)",
                }}
                numberOfPieces={50}
                width={width}
                height={height}
                gravity={0.05}
              />
            )}
          </React.Fragment>
        )}
      </CanvasWrapper>
      <Footer />
    </React.Fragment>
  );
};

export default Preview;
