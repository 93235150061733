import {
  Category,
  Font,
  FONT_FAMILY_DEFAULT,
  FontManager,
  Options,
  OPTIONS_DEFAULTS,
  Script,
  SortOption,
  Variant,
} from "@samuelmeuli/font-manager";
import React, {
  KeyboardEvent,
  FC,
  ReactElement,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import styled, { keyframes } from "styled-components";
import { sendAmplitudeData } from "../service/amplitude";
import Close from "@kiwicom/orbit-components/lib/icons/Close";

type LoadingStatus = "loading" | "finished" | "error";

interface Props {
  // Required props
  apiKey: string;
  show: boolean;

  // Optional props
  activeFontFamily?: string;
  onChange?: (fontFamily: Font["family"]) => void;
  pickerId?: string;
  families?: string[];
  categories?: Category[];
  scripts?: Script[];
  variants?: Variant[];
  filter?: (font: Font) => boolean;
  limit?: number;
  sort?: SortOption;
}

/**
 * Return the fontId based on the provided font family
 */
function getFontId(fontFamily: string): string {
  return fontFamily.replace(/\s+/g, "-").toLowerCase();
}

const IconButton = styled.button`
  width: 50px !important;
  height: 50px !important;
  background: white !important;
  border-radius: 50% !important;

  display: flex;
  justify-content: center !important;
  align-items: center;
`;

const FontPickerWrapper = styled.div`
  width: 50px !important;
  height: 50px !important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2) !important;
  border-radius: 50% !important;
  overflow: visible;

  &.expanded ul {
    max-height: 300px !important;
  }
`;
const fadeIn = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `;
const FontOptionWrapper = styled.ul`
  bottom: 60px;
  right: 10px;
  width: max-content !important;
  height: auto !important;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25) !important;
  border-radius: 8px !important;
  background: white !important;
  overflow: hidden !important;

  animation: ${fadeIn} ease 0.3s;

  .font-button {
    &:hover {
      background: rgba(0, 0, 0, 0.2);
      /* color: white; */
    }
    &.active-font {
      background: rgba(0, 0, 0, 0.7);
      color: white;
    }
  }
`;

/**
 * <ul> with all font families
 */
const FontList = React.forwardRef<
  HTMLUListElement,
  {
    suffix: string;
    fonts: Font[];
    activeFontFamily: string;
    onHoverChange: Props["onChange"];
    onSelectionChange: Props["onChange"];
  }
>(
  (
    {
      suffix = "",
      fonts,
      activeFontFamily,
      onHoverChange = () => {},
      onSelectionChange = () => {},
    },
    ref
  ): ReactElement => {
    /**
     * Update the active font on change events
     */
    const onChange = (handleChange: NonNullable<Props["onChange"]>) => (
      e: React.MouseEvent | KeyboardEvent
    ): void => {
      const target = e.target as HTMLButtonElement;
      const newActive = target.textContent;
      if (!newActive) {
        throw Error(`Missing font family in clicked font button`);
      }
      handleChange(newActive);
    };

    const onHover = onChange(onHoverChange);
    const onSelection = onChange(onSelectionChange);

    return (
      <FontOptionWrapper ref={ref} className="font-list">
        {fonts.map(
          (font): ReactElement => {
            const isActive = font.family === activeFontFamily;
            const fontId = getFontId(font.family);
            return (
              <li key={fontId} className="font-list-item">
                <button
                  type="button"
                  id={`font-button-${fontId}${suffix}`}
                  className={`font-button ${isActive ? "active-font" : ""}`}
                  onClick={onSelection}
                  onKeyPress={onSelection}
                  onMouseOver={onHover}
                >
                  {font.family}
                </button>
              </li>
            );
          }
        )}
      </FontOptionWrapper>
    );
  }
);

const defaultProps: Required<Props> = {
  apiKey: "",
  show: false,
  activeFontFamily: FONT_FAMILY_DEFAULT,
  onChange: (): void => {},
  pickerId: OPTIONS_DEFAULTS.pickerId,
  families: OPTIONS_DEFAULTS.families,
  categories: OPTIONS_DEFAULTS.categories,
  scripts: OPTIONS_DEFAULTS.scripts,
  variants: OPTIONS_DEFAULTS.variants,
  filter: OPTIONS_DEFAULTS.filter,
  limit: OPTIONS_DEFAULTS.limit,
  sort: OPTIONS_DEFAULTS.sort,
};

const FontPicker: FC<Props> = (props = defaultProps) => {
  const {
    apiKey,
    show,
    activeFontFamily,
    onChange,
    pickerId,
    families,
    categories,
    scripts,
    variants,
    filter,
    limit,
    sort,
  } = { ...defaultProps, ...props };
  const options: Options = {
    pickerId,
    families,
    categories,
    scripts,
    variants,
    filter,
    limit,
    sort,
  };
  // Instance of the FontManager class used for managing, downloading and applying fonts
  const [fontManager] = useState<FontManager>(
    new FontManager(apiKey, activeFontFamily, options)
  );
  const [expanded, setExpanded] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("loading");
  const [fonts, setFonts] = useState<Font[]>([]);
  // tempFont to store the last clicked font family
  const [tempFont, setTempFont] = useState(activeFontFamily);
  const ref = useRef<HTMLUListElement>(null);

  useEffect(() => {
    // Generate font list
    fontManager
      .init()
      .then((): void => {
        setLoadingStatus("finished");
      })
      .catch((err: Error): void => {
        // On error: Log error message
        setLoadingStatus("error");
        console.error("Error trying to fetch the list of available fonts");
        console.error(err);
      });
  }, [fontManager]);

  useEffect(() => {
    if (loadingStatus === "finished") {
      // Extract and sort font list
      const sortedFonts = Array.from(fontManager.getFonts().values());
      if (sort === "alphabet") {
        sortedFonts.sort((font1: Font, font2: Font): number =>
          font1.family.localeCompare(font2.family)
        );
      }
      setFonts(sortedFonts);
    }
  }, [fontManager, sort, loadingStatus]);

  /**
   * Expand/collapse the picker's font list
   */
  const toggleExpanded = useCallback(
    (): void => setExpanded((exp) => !exp),
    []
  );

  /**
   * Close font list if clicked on outside of element
   */
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (event.target === document.querySelector(".dropdown-button")) {
        return;
      }
      if (ref.current && !ref.current.contains(event.target as Element)) {
        toggleExpanded();
      }
    },
    [ref, toggleExpanded]
  );

  /**
   * Open font picker and send data to amplitude
   */
  const openFontPicker = () => {
    toggleExpanded();
    sendAmplitudeData("[Editor] Open font picker");
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  /**
   * Update the active font on font button click from FontList
   * and send data to amplitude
   */
  const onSelectionChange = (activeFontFamily: string) => {
    setTempFont(activeFontFamily);
    toggleExpanded();
    sendAmplitudeData("[Editor] Choose a font", {
      "font-family": activeFontFamily,
    });
  };

  useEffect(() => {
    // change global state of active font family to temp font
    // since user might just hover and didn't click a new font
    onChange(tempFont);
  }, [expanded, tempFont, onChange]);

  if (!show) {
    return null;
  }

  // Render font picker button and attach font list to it
  return (
    <FontPickerWrapper
      id={`font-picker${fontManager.selectorSuffix}`}
      className={expanded ? "expanded" : ""}
    >
      <IconButton
        type="button"
        className="dropdown-button"
        onClick={openFontPicker}
        onKeyPress={openFontPicker}
      >
        {expanded ? <Close /> : "Aa"}
      </IconButton>
      {loadingStatus === "finished" && expanded && (
        <FontList
          suffix={fontManager.selectorSuffix}
          fonts={fonts}
          activeFontFamily={tempFont}
          onHoverChange={onChange}
          onSelectionChange={onSelectionChange}
          ref={ref}
        />
      )}
    </FontPickerWrapper>
  );
};

export default FontPicker;
