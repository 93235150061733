import React, { FunctionComponent } from "react";
import styled, { keyframes, createGlobalStyle, css } from "styled-components";

import EnvBackImg from "./../assets/en_back.png";
import EnvFrontImg from "./../assets/en_front.png";

import { useRecoilState } from "recoil";
import { stepsState, envFilterState } from "../store/atom";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

// const baseSize = 500;
interface EnvProps {
  baseSize: number;
  bottom?: number;
  top?: number;
  left?: string | number;
  hue?: number;
}
const Wrapper = styled.div<EnvProps>`
  width: ${({ baseSize }) => `${baseSize}px`};
  height: ${({ baseSize }) => `${(baseSize * 3) / 4}px`};

  position: absolute;
  bottom: ${({ bottom }) => `${bottom}px`};
  top: ${({ top }) => (top ? `${top}px` : "inherit")};
  left: ${({ left }) => (left ? `${left}` : "inherit")};
  transform: translate(-50%, 0);

  animation: ${fadeIn} 0.3s ease;
`;

const Envhead = styled.div``;
const EnvBase = styled.div<EnvProps>`
  width: ${({ baseSize }) => `${baseSize}px`};
  height: auto;

  position: absolute;

  img {
    width: 100%;
    height: auto;
    filter: ${({ hue }) => (hue ? `hue-rotate(${hue}deg)` : `inherit`)};
  }
`;
const EnvFront = styled(EnvBase)<EnvProps>`
  z-index: 2;
`;
const EnvBack = styled(EnvBase)<EnvProps>`
  z-index: 0;
  top: ${({ baseSize }) => `${-baseSize / 4}px`};
`;

export const Env: FunctionComponent<EnvProps> = (props) => {
  const [steps, setSteps] = useRecoilState(stepsState);
  const [envFilterValue, setEnvFilterValue] = useRecoilState(envFilterState);

  return (
    <Wrapper
      baseSize={props?.baseSize}
      bottom={props?.bottom}
      top={props?.top}
      left={props?.left}
      hue={envFilterValue}
    >
      <EnvFront baseSize={props?.baseSize} hue={envFilterValue}>
        <img src={EnvFrontImg} alt={"digital envelope front"} />
      </EnvFront>
      {props.children}
      <EnvBack baseSize={props?.baseSize} hue={envFilterValue}>
        <img src={EnvBackImg} alt={"digital envelope back"} />
      </EnvBack>
    </Wrapper>
  );
};

export default Env;
